import type { ComponentType } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { randomColor } from "https://framer.com/m/framer/utils.js@^0.9.0"

// Learn more: https://www.framer.com/docs/guides/overrides/

export function withDynamicViewportHeight(Component): ComponentType {
    return (props: any) => {
        return (
            <Component
                {...props}
                style={{
                    ...props.style,
                    height: "100dvh",
                }}
            />
        )
    }
}
